import { datadogLogs } from '@datadog/browser-logs';
import { CacheProvider } from '@emotion/react';
import { RemixBrowser } from '@remix-run/react';
import type PSPDFKitTypes from 'pspdfkit';
import { ReactNode, startTransition, useMemo, useState } from 'react';
import { hydrateRoot } from 'react-dom/client';
import globalConfig from '~/configs/global';
import ClientStyleContext from './utils/ClientStyleContext';
import createEmotionCache from './utils/createEmotionCache';
import { getEnv } from './utils/environment';

declare global {
    interface Window {
        ENV: Record<string, string>;
        USER: Record<string, string>;
        _hsq: any;
        pendo: any;
        dataLayer?: any;
        PSPDFKit?: typeof PSPDFKitTypes;
        addGtmConsentListener?: (callback: any) => void;
        store?: any; // So we can make the redux store available in cypress tests;
        DD_LOGS: any;
    }
}

if (window.ENV && globalConfig.featureFlags.isErrorTrackingEnvironment) {
    const datadogEnvironment = getEnv().startsWith('prod') ? 'prod' : getEnv().startsWith('stage') ? 'stage' : 'test';

    datadogLogs.init({
        clientToken: 'pub7ffa0fca9740d1999dee670eceec332b',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: `allvit-frontend-client-${getEnv()}`,
        env: datadogEnvironment,
        version: '9f9576acfe4af15d372361a43a10a0312d8a51c6',
        beforeSend: (log) => {
            if (
                log?.message &&
                (log.message.includes('chrome-extension://') ||
                    log.message.includes('hubspot') ||
                    log.message.includes('hscollectedforms') ||
                    log.message.includes('hubapi') ||
                    log.message.includes('google-analytics') ||
                    log.message.includes('snapchat') ||
                    log.message.includes('capig.stape') ||
                    log.message.includes('localhost'))
            ) {
                return false;
            }

            log.network = {};
            return true;
        },
    });
}

// Expose mui emotion cache, so we can manually inject styles into html headers (see root.tsx)
function ClientStylesCacheProvider({ children }: { children: ReactNode }) {
    const [cache, setCache] = useState(createEmotionCache());

    const clientStyleContextValue = useMemo(
        () => ({
            reset() {
                setCache(createEmotionCache());
            },
        }),
        [],
    );

    return (
        <ClientStyleContext.Provider value={clientStyleContextValue}>
            <CacheProvider value={cache}>{children}</CacheProvider>
        </ClientStyleContext.Provider>
    );
}

const hydrate = () => {
    startTransition(() => {
        hydrateRoot(
            document,
            <ClientStylesCacheProvider>
                <RemixBrowser />
            </ClientStylesCacheProvider>,
        );
    });
};

if (window.requestIdleCallback) {
    window.requestIdleCallback(hydrate);
} else {
    // Safari doesn't support requestIdleCallback
    // https://caniuse.com/requestidlecallback
    window.setTimeout(hydrate, 1);
}
